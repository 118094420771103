import React from "react";

import { useParams } from 'react-router-dom';

import Garrix from "../../src/Garrix.png"
import artboard from "../../src/Artboard 14.png"


import '../Styles/project.css'
import SudokuSolver from "./sodoku";

export default function Project() {

    const { projectId } = useParams();

    const project = [
        {
            name: 'OnlyFriends',
            tag: "A place to meet new friends at school!",
            type: 'Web Development',
            img: "https://github.com/b4sleal/OnlyFriends/raw/main/examples/example1.gif",
            projectType: 'Club Project',
            timeline: '2 Months',
            role: 'Front End Developer',
            techStack: ["NodeJS", "React", "Firebase", "Bootstrap", "SocketIO", "Express", "SpotifyAPI"],
            project: "OnlyFriends is a social platform designed exclusively for university students looking to make friends or date on campus. Our platform offers a unique and personalized experience that connects students with like-minded people who share their interests and programs. To ensure the authenticity of our community, students must verify their student status to access the platform. Our sophisticated algorithms match users based on their interests, hobbies, and programs, making it easy to find people they can relate to. At OnlyFriends, we prioritize the safety and privacy of our users. We have implemented robust security measures to prevent malicious use and protect user data. This ensures that the platform remains a safe and secure space for university students to connect with each other. We offer our platform completely free of charge, with no hidden fees or premium subscriptions required. Our mission is to provide a convenient and accessible way for university students to make meaningful connections with each other.",
            context: "The idea for OnlyFriends came from the common struggle many university students face when trying to find like-minded individuals on a large campus. The project was initiated as part of a university club hackathon where the objective was to address real-world issues using technology. As a Front-End Developer, I faced the challenge of implementing a seamless, intuitive UI while ensuring real-time data handling. Working on this project not only strengthened my skills in various technologies but also gave me a sense of how different layers of a web application interact with each other. The experience was extremely rewarding, as the application has now grown into a thriving platform used by hundreds of students at our university.",
            gitHub: "https://github.com/b4sleal/OnlyFriends",
        },
        {
            name: 'Sudoku Solver',
            tag: "A tool to solve every Sudoku Puzzle",
            type: 'Software Development',
            img: 'erk',
            projectType: 'Personal Project',
            timeline: '2 Days',
            role: 'Project Lead',
            techStack: ["React", "Javascript", "CSS"],
            project: "A sudoku solver is a software program or algorithm designed to solve sudoku puzzles, which are logic-based games that involve filling a 9x9 grid with digits from 1 to 9 without repetition. Solving a sudoku puzzle can be a challenging task that requires a combination of logical deduction and trial-and-error. A sudoku solver can provide a quick and accurate solution to a puzzle, without the need for human input. The purpose of a sudoku solver goes beyond simply providing a solution. It can also help users understand the logic and strategies used to solve the puzzle. By analyzing the steps taken by the solver, users can learn new techniques and improve their skills. This can be especially helpful for beginners who are just learning how to solve sudoku puzzles. Overall, a sudoku solver is a valuable tool for anyone who enjoys sudoku puzzles. It can save time, improve skills, and provide a deeper understanding of the logic behind the game.",
            context: "This project was born out of my interest in applying Combinatorics algorithms, learned in class, to real-world problems. After researching various methods, I chose to use Integer Programming to solve Sudoku puzzles. I then coded this algorithm in JavaScript and integrated it into a user-friendly tool using React and CSS. The project not only challenged my coding skills but also deepened my understanding of algorithmic problem-solving. Completed in two days, this Sudoku solver serves not only as an efficient problem-solver but also as an educational resource for users to learn Sudoku-solving techniques.",
        },
        {
            name: 'Martin Garrix Ecommerce Website',
            tag: 'An online store for fans of Martin Garrix',
            type: 'Web Development',
            img: Garrix,
            projectType: 'Personal Project',
            timeline: '2 Weeks',
            role: 'Project Lead',
            techStack: ["HTML", "CSS", "Javascript"],
            project: "The Martin Garrix Ecommerce Website serves as a central hub for fans of the world-renowned DJ and music producer to purchase merchandise, music albums, and even event tickets. The website aims to provide an engaging and seamless user experience, from browsing to checkout. The product catalog is easily navigable, featuring a range of apparel, accessories, and music offerings. An intuitive UI coupled with responsive design ensures that users can shop comfortably from any device.",
            context: "This project was conceived out of personal admiration for Martin Garrix and his music. As the Project Lead, I took charge of both front-end and back-end aspects, although the focus was predominantly on the front-end. HTML was used for structuring the website, CSS for styling, and Javascript for dynamic functionalities. Unlike traditional ecommerce solutions that rely on full-fledged back-ends and third-party payment integrations, this was a front-end-centric project executed within a two-week timeline. While it doesn't offer real transaction capabilities, the project serves as a concept model that demonstrates how an ecommerce platform for an artist like Martin Garrix could look and function. The source code is publicly available, inviting collaboration and improvements.",
            gitHub: "https://github.com/wesgolf/Martin-Garrix-Ecommerce-Website",
            demo: "https://sparkling-kitsune-6d6b5b.netlify.app"
        },
        {
            name: "HGCC Yardage Book",
            tag: "A comprehensive yardage book for the HGCC Golf Course",
            type: "Graphic Design",
            img: "https://a.espncdn.com/i/infographics/20151013_golfFrys/frysOpen_8th-1_1296.jpg",
            projectType: "Personal Project",
            timeline: "1 Month",
            role: "Project Lead",
            techStack: ["Adobe Photoshop", "Adobe Illustrator", "Adobe InDesign"],
            project: "The HGCC Yardage Book project aimed to create a visually appealing and easy-to-use yardage book for golfers. The yardage book includes critical distances, hazards, and optimal lines of play for each hole. The book is designed to be both practical and aesthetically pleasing, offering golfers an essential tool to improve their game.",
            context: "Being a fan of golf, I wanted to contribute to the community by designing a yardage book that combines function with form. Adobe Photoshop was used for image editing, Adobe Illustrator for vector-based illustrations, and Adobe InDesign for layout and design. The project took one month to complete and the final product was made available for free download to any interested golfers. Feedback from users has been overwhelmingly positive, confirming the book's utility and design quality.",
        },
        {
            name: "Taka Sushi Menu Design",
            techStack: ["Adobe Photoshop", "Adobe Illustrator", "Adobe InDesign"],
        },
        {
           name: "Connect +",
           techStack: ["Adobe XD", "Adobe Photoshop", "Adobe Illustrator", "Swift"],
           role: "Project Lead",
           timeline: "2 Months",
           img: artboard,
           projectType: "School Project",
        },
        {
            name: "St. Catherines Ad & Sales",
            techStack: ["Figma", "React", "CSS"]
        }
    ]

    return (
        <>
            <div id="bg-wrap" >
                <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
                    <defs>
                        <radialGradient id="Gradient1" cx="50%" cy="50%" fx="0.441602%" fy="50%" r=".5"><animate attributeName="fx" dur="34s" values="0%;3%;0%" repeatCount="indefinite"></animate><stop offset="0%" stopColor="rgba(255, 0, 255, 1)"></stop><stop offset="100%" stopColor="rgba(255, 0, 255, 0)"></stop></radialGradient>
                        <radialGradient id="Gradient2" cx="50%" cy="50%" fx="2.68147%" fy="50%" r=".5"><animate attributeName="fx" dur="23.5s" values="0%;3%;0%" repeatCount="indefinite"></animate><stop offset="0%" stopColor="rgba(255, 255, 0, 1)"></stop><stop offset="100%" stopColor="rgba(255, 255, 0, 0)"></stop></radialGradient>
                        <radialGradient id="Gradient3" cx="50%" cy="50%" fx="0.836536%" fy="50%" r=".5"><animate attributeName="fx" dur="21.5s" values="0%;3%;0%" repeatCount="indefinite"></animate><stop offset="0%" stopColor="rgba(0, 255, 255, 1)"></stop><stop offset="100%" stopColor="rgba(0, 255, 255, 0)"></stop></radialGradient>
                        <radialGradient id="Gradient4" cx="50%" cy="50%" fx="4.56417%" fy="50%" r=".5"><animate attributeName="fx" dur="23s" values="0%;5%;0%" repeatCount="indefinite"></animate><stop offset="0%" stopColor="rgba(0, 255, 0, 1)"></stop><stop offset="100%" stopColor="rgba(0, 255, 0, 0)"></stop></radialGradient>
                        <radialGradient id="Gradient5" cx="50%" cy="50%" fx="2.65405%" fy="50%" r=".5"><animate attributeName="fx" dur="24.5s" values="0%;5%;0%" repeatCount="indefinite"></animate><stop offset="0%" stopColor="rgba(0,0,255, 1)"></stop><stop offset="100%" stopColor="rgba(0,0,255, 0)"></stop></radialGradient>
                        <radialGradient id="Gradient6" cx="50%" cy="50%" fx="0.981338%" fy="50%" r=".5"><animate attributeName="fx" dur="25.5s" values="0%;5%;0%" repeatCount="indefinite"></animate><stop offset="0%" stopColor="rgba(255,0,0, 1)"></stop><stop offset="100%" stopColor="rgba(255,0,0, 0)"></stop></radialGradient>
                    </defs>
                    <rect x="90.744%" y="1.18473%" width="100%" height="100%" fill="url(#Gradient1)" transform="rotate(334.41 50 50)"><animate attributeName="x" dur="20s" values="25%;0%;25%" repeatCount="indefinite"></animate><animate attributeName="y" dur="21s" values="0%;25%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="7s" repeatCount="indefinite"></animateTransform></rect>
                    <rect x="-2.17916%" y="35.4267%" width="100%" height="100%" fill="url(#Gradient2)" transform="rotate(255.072 50 50)"><animate attributeName="x" dur="23s" values="-25%;0%;-25%" repeatCount="indefinite"></animate><animate attributeName="y" dur="24s" values="0%;50%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="12s" repeatCount="indefinite"></animateTransform>
                    </rect>
                    <rect x="9.00483%" y="14.5733%" width="100%" height="100%" fill="url(#Gradient3)" transform="rotate(139.903 50 50)"><animate attributeName="x" dur="25s" values="0%;25%;0%" repeatCount="indefinite"></animate><animate attributeName="y" dur="12s" values="0%;25%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="360 50 50" to="0 50 50" dur="9s" repeatCount="indefinite"></animateTransform>
                    </rect>
                </svg>
            </div>
            <div className="hero">
                <div>
                    <div className="title">
                        <p>{project[projectId].type}</p>
                        <h1>{project[projectId].name}</h1>
                        <h2>{project[projectId].tag}</h2>
                    </div>
                    <div className="gridskills">
                        <div className="item1skills">
                            <h4>Tech Stack</h4>
                            <ul>
                                {project[projectId].techStack.map((tech, index) => (
                                    <li key={index}>{tech}</li>
                                ))}
                            </ul>
                        </div>

                        <div className="smaller last">
                            <h4>Project Type</h4>
                            <p>{project[projectId].projectType}</p>
                        </div>
                        <div className="smaller last">
                            <h4>Timeline</h4>
                            <p>{project[projectId].timeline}</p>
                        </div>
                        <div className="smaller last">
                            <h4>Role</h4>
                            <p>{project[projectId].role}</p>
                        </div>
                    </div>
                </div>
                <div>
                    {(projectId !== 1) ? <img src={project[projectId].img} alt="project"/> : <SudokuSolver />}
                    {(projectId !== "1" && projectId !== "3"  && projectId !== "5") ? <div className="link-container">
                        <a href={project[projectId].gitHub} target="_blank" rel="noreferrer">GitHub</a>
                        <a href={project[projectId].demo} target="_blank" rel="noreferrer">Demo</a>
                    </div> : <p></p> }

                </div>
            </div>
            <div className="gridproject">
                <div className="item">
                    <h4>Product</h4>
                    <p>{project[projectId].project}</p>
                </div>
                <div className="item">
                    <h4>Context</h4>
                    <p>{project[projectId].context}</p>
                </div>
            </div>
        </>
    )
}