import React, { useState } from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';


import '../Styles/contactstyle.css'

export default function Contact({ onContactClick }) {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [reason, setReason] = useState("")
    const [note, setNote] = useState("")


    const handleContactClick = (event) => {
        event.preventDefault();
        onContactClick();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission here
        console.log(name, email, reason, note);
    }

    return (
        <div className="contactbackground">
            <div className="contact">
                <h4>Contact</h4>
                <FontAwesomeIcon icon={faXmark} onClick={handleContactClick} className="x" />
                <form onSubmit={handleSubmit} >
                    <label htmlFor="Name">Name</label> <br />
                    <input id="name" value={name} onChange={(event) => setName(event.target.value)} /> <br />
                    <label htmlFor="Email">Email</label> <br />
                    <input id="email" value={email} onChange={(event) => setEmail(event.target.value)} /> <br />
                    <label htmlFor="Reason">Reason for Contacting</label> <br />
                    <select id="reason" value={reason} onChange={(event) => setReason(event.target.value)}>
                        <option value="Hello">Hello</option>
                        <option value="World">World</option>
                    </select> <br />
                    <label htmlFor="Note">Note</label> <br />
                    <textarea id="note" value={note} onChange={(event) => setNote(event.target.value)} /> <br />
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    )
}