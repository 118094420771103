import React from 'react';

import '../Styles/navbarstyle.css'

export default function Navbar({ onContactClick }) {

  const handleContactClick = (event) => {
    event.preventDefault();
    onContactClick();
  };

  return (
    <ul className='navbarlist'>
      <li><a href="/" role="button" className="li">Home</a></li>
      <li><a href="/#skills" role="button" className="li">Skills</a></li>
      <li><a href="/#projects" role="button" className="li">Projects</a></li>
      <li><a href='# ' className="li" role="button" onClick={handleContactClick}>Contact</a></li>
    </ul>
  );
}