import React, { useState } from "react";
import "../Styles/sodoku.css"

export default function SudokuSolver() {
  const [grid, setGrid] = useState(Array.from({ length: 9 }, () => Array(9).fill(0)));

  const isValid = (board, row, col, num) => {
    // Check row
    for (let x = 0; x < 9; x++) {
      if (board[row][x] === num && x !== col) {
        return false;
      }
    }
    // Check column
    for (let x = 0; x < 9; x++) {
      if (board[x][col] === num && x !== row) {
        return false;
      }
    }
    // Check 3x3 grid
    const startRow = row - (row % 3);
    const startCol = col - (col % 3);
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        if (board[i + startRow][j + startCol] === num && (i + startRow !== row || j + startCol !== col)) {
          return false;
        }
      }
    }
    return true;
  };
  

  const solveSudoku = (board, row, col) => {
    if (row === 8 && col === 9) {
      return true;
    }

    if (col === 9) {
      row++;
      col = 0;
    }

    if (board[row][col] !== 0) {
      return solveSudoku(board, row, col + 1);
    }

    for (let num = 1; num <= 9; num++) {
      if (isValid(board, row, col, num)) {
        board[row][col] = num;
        if (solveSudoku(board, row, col + 1)) {
          return true;
        }
      }
      board[row][col] = 0;
    }

    return false;
  };

  const handleInputChange = (e, i, j) => {
    const value = parseInt(e.target.value);
    let newGrid = [...grid];
    newGrid[i][j] = value;
    setGrid(newGrid);
  };

  const resetGrid = () => {
    setGrid(Array.from({ length: 9 }, () => Array(9).fill(0)));
  };

  const handleSolve = () => {
    // Validate initial board configuration
    for (let i = 0; i < 9; i++) {
      for (let j = 0; j < 9; j++) {
        const num = grid[i][j];
        if (num !== 0 && !isValid(grid, i, j, num)) {
          alert("Invalid board configuration");
          return;
        }
      }
    }

    let newGrid = [...grid];
    if (solveSudoku(newGrid, 0, 0)) {
      setGrid(newGrid);
    } else {
      alert("No solution exists");
    }
  };

  return (
    <>

      <span className="sudoku-grid">
        {grid.map((row, i) => (
          <span key={i} className="sudoku-row">
            {row.map((cell, j) => (
              <input
                key={j}
                type="number"
                value={cell === 0 ? "" : cell}
                onChange={(e) => handleInputChange(e, i, j)}
              />
            ))}
          </span>
        ))}
      </span>
      <div className="link-container">
        <p onClick={resetGrid}>Reset</p>
        <p onClick={handleSolve}>Solve</p>
      </div>
    </>
  );
}
