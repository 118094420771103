import 'aos/dist/aos.css';
import '../Styles/homestyle.css';

import '../Styles/mousescroll.css'

import React, { useState } from "react";

import { faReact } from '@fortawesome/free-brands-svg-icons';

import { faPenNib } from '@fortawesome/free-solid-svg-icons';

import { faLaptopCode } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Artboard1 from "../../src/Artboard 1.png"
import sushi from "../../src/sushi.png"
import garrix from '../../src/Garrix.png'



export default function Homepage() {



    /* Education and Experience */
    const [activeIndex, setActiveIndex] = useState(-1)

    function toggleActiveIndex(index) {
        setActiveIndex(index);
    }



    /* Filtering on part 3 */
    const [filter, setFilter] = useState('All')

    const projects = [
        {
            name: 'OnlyFriends',
            type: 'Web Development',
            filtertype: 'Web Development',
            img: "https://github.com/b4sleal/OnlyFriends/raw/main/examples/example1.gif",
            link: 'project/0'
        },
        {
            name: 'HGCC Yardage Book',
            type: 'Graphic Design',
            filtertype: 'Graphic Design',
            img: 'https://a.espncdn.com/i/infographics/20151013_golfFrys/frysOpen_8th-1_1296.jpg',
            link: 'project/3'
        },
        {
            name: 'Connect +',
            type: 'Mobile App Development/Design',
            filtertype: 'Web Development',
            img: Artboard1,
            link: 'project/5'
        },
        {
            name: 'Martin Garrix',
            type: 'E-Commerce Site',
            filtertype: 'Web Development',
            img: garrix,
            link: 'project/2'
        },
        {
            name: 'Taka Sushi',
            type: 'Menu Design',
            filtertype: 'Graphic Design',
            img: sushi,
            link: 'project/4'
        },
        {
            name: 'Sodoku Solver',
            type: 'Software Development',
            filtertype: "Software Development",
            img: 'https://thumbs.dreamstime.com/b/sudoku-puzzle-grid-as-background-closeup-view-216265578.jpg',
            link: 'project/1'
        }
    ];

    const handleFilterChange = (event) => {
        setFilter(event.target.innerText);
    };

    const filterProjects = (projects) => {
        if (filter === 'All') {
            return projects;
        } else {
            return projects.filter(project => project.filtertype === filter);
        }
    };

    const filteredProjects = filterProjects(projects);

    return (
        <>
            <div id="bg-wrap" >
                <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
                    <defs>
                        <radialGradient id="Gradient1" cx="50%" cy="50%" fx="0.441602%" fy="50%" r=".5"><animate attributeName="fx" dur="34s" values="0%;3%;0%" repeatCount="indefinite"></animate><stop offset="0%" stopColor="rgba(255, 0, 255, 1)"></stop><stop offset="100%" stopColor="rgba(255, 0, 255, 0)"></stop></radialGradient>
                        <radialGradient id="Gradient2" cx="50%" cy="50%" fx="2.68147%" fy="50%" r=".5"><animate attributeName="fx" dur="23.5s" values="0%;3%;0%" repeatCount="indefinite"></animate><stop offset="0%" stopColor="rgba(255, 255, 0, 1)"></stop><stop offset="100%" stopColor="rgba(255, 255, 0, 0)"></stop></radialGradient>
                        <radialGradient id="Gradient3" cx="50%" cy="50%" fx="0.836536%" fy="50%" r=".5"><animate attributeName="fx" dur="21.5s" values="0%;3%;0%" repeatCount="indefinite"></animate><stop offset="0%" stopColor="rgba(0, 255, 255, 1)"></stop><stop offset="100%" stopColor="rgba(0, 255, 255, 0)"></stop></radialGradient>
                        <radialGradient id="Gradient4" cx="50%" cy="50%" fx="4.56417%" fy="50%" r=".5"><animate attributeName="fx" dur="23s" values="0%;5%;0%" repeatCount="indefinite"></animate><stop offset="0%" stopColor="rgba(0, 255, 0, 1)"></stop><stop offset="100%" stopColor="rgba(0, 255, 0, 0)"></stop></radialGradient>
                        <radialGradient id="Gradient5" cx="50%" cy="50%" fx="2.65405%" fy="50%" r=".5"><animate attributeName="fx" dur="24.5s" values="0%;5%;0%" repeatCount="indefinite"></animate><stop offset="0%" stopColor="rgba(0,0,255, 1)"></stop><stop offset="100%" stopColor="rgba(0,0,255, 0)"></stop></radialGradient>
                        <radialGradient id="Gradient6" cx="50%" cy="50%" fx="0.981338%" fy="50%" r=".5"><animate attributeName="fx" dur="25.5s" values="0%;5%;0%" repeatCount="indefinite"></animate><stop offset="0%" stopColor="rgba(255,0,0, 1)"></stop><stop offset="100%" stopColor="rgba(255,0,0, 0)"></stop></radialGradient>
                    </defs>
                    <rect x="90.744%" y="1.18473%" width="100%" height="100%" fill="url(#Gradient1)" transform="rotate(334.41 50 50)"><animate attributeName="x" dur="20s" values="25%;0%;25%" repeatCount="indefinite"></animate><animate attributeName="y" dur="21s" values="0%;25%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="7s" repeatCount="indefinite"></animateTransform></rect>
                    <rect x="-2.17916%" y="35.4267%" width="100%" height="100%" fill="url(#Gradient2)" transform="rotate(255.072 50 50)"><animate attributeName="x" dur="23s" values="-25%;0%;-25%" repeatCount="indefinite"></animate><animate attributeName="y" dur="24s" values="0%;50%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="12s" repeatCount="indefinite"></animateTransform>
                    </rect>
                    <rect x="9.00483%" y="14.5733%" width="100%" height="100%" fill="url(#Gradient3)" transform="rotate(139.903 50 50)"><animate attributeName="x" dur="25s" values="0%;25%;0%" repeatCount="indefinite"></animate><animate attributeName="y" dur="12s" values="0%;25%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="360 50 50" to="0 50 50" dur="9s" repeatCount="indefinite"></animateTransform>
                    </rect>
                </svg>
            </div>
            <div className='herotext'>
                <h1>Wesley Robertson</h1>
                <h2>Front End Developer and Designer</h2>
                <a className="mouse_scroll" href='#skills'>

                    <div className="mouse">
                        <div className="wheel"></div>
                    </div>
                    <div>
                        <span className="m_scroll_arrows unu"></span>
                        <span className="m_scroll_arrows doi"></span>
                        <span className="m_scroll_arrows trei"></span>
                    </div>
                </a>
            </div>
            <div className='part2' id='skills'>
                <h3>Expertise</h3>
                <div className='skillscontainer'>
                    <div className='skill'>
                        <h4>Front-End Development</h4>
                        <FontAwesomeIcon icon={faReact} className='icon1' />
                        <p>Proficient in crafting the client-side of web applications. Well-versed in HTML, CSS, and JavaScript, I focus on building user interfaces that are not only visually pleasing but also user-friendly and easy to navigate.</p>
                    </div>
                    <div className='skill'>
                        <h4>UI/UX Design</h4>
                        <FontAwesomeIcon icon={faPenNib} className='icon2' />
                        <p>Skilled in the art of designing user-centric interfaces for web and mobile applications. Leveraging principles of UX design, I employ tools like Adobe XD and Figma for wireframing and prototyping.</p>
                    </div>
                    <div className='skill'>
                        <h4>Software Engineering</h4>
                        <FontAwesomeIcon icon={faLaptopCode} className='icon3' />
                        <p>Experienced in developing software solutions tailored to address specific business challenges or user requirements. Adept in programming languages including Python and JavaScript.</p>
                    </div>
                </div>
            </div>

            <div className='part3' id='projects'>
                <h3>Projects</h3>
                <div className='filterdiv'>
                    <p className='bold'>Filter by:</p>
                    <p onClick={handleFilterChange} className={filter === 'All' ? 'bold' : ''}>All</p>
                    <p onClick={handleFilterChange} className={filter === 'Web Development' ? 'bold' : ''}>Web Development</p>
                    <p onClick={handleFilterChange} className={filter === 'Graphic Design' ? 'bold' : ''}>Graphic Design</p>
                    <p onClick={handleFilterChange} className={filter === 'Software Development' ? 'bold' : ''}>Software Development</p>
                </div>
                <div className='grid'>
                    {filteredProjects
                        .map((project, index) => (
                            <a
                                key={index}
                                className={index === 0 ? 'biggerdiv item1' : index === 4 ? 'biggerdiv item5' : 'smallerdiv'}
                                href={project.link}
                            >
                                <img src={project.img} alt="project"/>
                                <div className='info'>
                                    <h5>{project.name}</h5>
                                    <p>{project.type}</p>
                                </div>
                            </a>
                        ))}
                </div>
            </div>
            <div>
                <h3>Education and Experience</h3>
                <div className='educationandexp'>
                    <div>
                        <h4>Education</h4>
                        <div className={activeIndex === 0 ? "activeExpCont" : "expcont"} onClick={() => toggleActiveIndex(0)}>
                            <h5 className='expconth5'>University of Waterloo</h5>
                            <h6 className='expconth6'>Bachelor of Honors Mathematics</h6>
                            <h6 className='expconth62'>2020 - 2025</h6>
                            <ul className={activeIndex === 0 ? "activelist listt" : "hidden listt"}>
                                <li>Specialization: Computational Mathematics</li>

                                <li>Clubs:
                                    <ul>
                                        <li>Computer Science Club - General Member</li>
                                        <li>Pure Math Club - general Member</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className={activeIndex === 1 ? "activeExpCont" : "expcont"} onClick={() => toggleActiveIndex(1)}>
                            <h5 className='expconth5'>Wilfrid Laurier University</h5>
                            <h6 className='expconth6'>Bachelor of Business Administration</h6>
                            <h6 className='expconth62'>2020 - 2025</h6>
                            <ul className={activeIndex === 1 ? "activelist listt" : "hidden listt"}>
                                <li>Specialization: Undeclared</li>
                                <li>Clubs:
                                    <ul>
                                        <li>Sports Management Club - General Member</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <h4>Experience</h4>
                        <div className={activeIndex === 2 ? "activeExpCont" : "expcont"} onClick={() => toggleActiveIndex(2)}>
                            <h5 className='expconth5'>CiviConnect</h5>
                            <h6 className='expconth6'>Web Developer/Web Designer Co-op</h6>
                            <h6 className='expconth62'>Janurary 2023 - April 2023</h6>
                            <ul className={activeIndex === 2 ? "activelist listt" : "hidden listt"}>
                                <li>Collaborated with a team of three developers to create websites using HTML, CSS, and JavaScript</li>
                                <li>Worked on three website projects for different clients</li>
                                <li>Played a crucial role in coding and implementing various website features, including layout, user interface, navigation, and interactivity</li>
                                <li>Ensured that websites were optimized for search engines, accessible to all users, and met clients' requirements</li>
                            </ul>
                        </div>
                        <div className={activeIndex === 3 ? "activeExpCont" : 'expcont'} onClick={() => toggleActiveIndex(3)}>
                            <h5 className='expconth5'>Bateman MacKay</h5>
                            <h6 className='expconth6'>Staff Accountant Co-op</h6>
                            <h6 className='expconth62'>Janurary 2022 - April 2022</h6>
                            <ul className={activeIndex === 3 ? "activelist listt" : "hidden listt"}>
                                <li>Prepared and completed 600+ tax returns using Taxprep and Caseware</li>
                                <li>Identified savings for clients, recommended tax-cutting measures, and ensured compliance with regulations</li>
                                <li>Built positive client relationships through timely communication and a service-focused approach</li>
                                <li>Actively contributed to team meetings, showed responsiveness to supervisor feedback and direction</li>
                            </ul>
                        </div>
                        <div className={activeIndex === 4 ? "activeExpCont" : 'expcont'} onClick={() => toggleActiveIndex(4)}>
                            <h5 className='expconth5'>DesignWerx</h5>
                            <h6 className='expconth6'>Graphic Designer</h6>
                            <h6 className='expconth62'>September 2018 - June 2019</h6>
                            <ul className={activeIndex === 4 ? "activelist listt" : 'hidden listt'}>
                                <li>Created designs for marketing materials such as brochures, flyers, and banners using Adobe Creative Suite</li>
                                <li>Developed new design concepts and layouts for digital and print media, based on client requirements and preferences</li>
                                <li>Collaborated with cross-functional teams, including marketing and product development, to ensure brand consistency and effective communication of key messages</li>
                                <li>Presented design ideas and concepts to clients, and made revisions based on feedback to ensure client satisfaction</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
