import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import  Homepage  from './Pages/homepage.js'
import Project from './Pages/project.js'
import Navbar from './Components/navbar.js'
import SodokuSolver from './Pages/sodoku.js'

import Contact from './Components/contact.js';


function App() {

  const [showContact, setShowContact] = useState(false);

  const handleContactClick = () => {
    setShowContact(!showContact);
  };
  

  return (
    <BrowserRouter>
      <div className="App">
        <Navbar  onContactClick={handleContactClick}/>
        {showContact && <Contact onContactClick={handleContactClick}/>}
       {/*<Blob /> */}
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="/project/:projectId" element={<Project />} />
          <Route path="/sodoku" element={<SodokuSolver />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

/* Export */
export default App;